<template>
  <div class="login-bg">
    <div class="login-auto a-auto a-flex a-flex-ai-cen a-flex-jc-btw">
      <div class="login-left">
        <div class="login-title">{{title}}</div>
        <div class="login-xbg"></div>
      </div>
      <!-- 右侧 -->
      <div v-if="isLogin" class="login-right form-width">
        <!-- 登录 -->
        <div class="login-form">
          <div class="login-form-title">用户登录</div>
          <el-form :model="ruleForm" :rules="rules" ref="ruleForm" class="demo-ruleForm" @keydown.enter.native="submitForm('ruleForm')">
            <!-- <el-form-item prop="user">
              <el-select v-model="ruleForm.schoolId" clearable placeholder="请选择学校" :disabled="disabled" :class="{'login-form-input':true,'schoolColor':disabled}">
                <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value"> </el-option>
              </el-select>
            </el-form-item> -->
            <el-form-item prop="username">
              <el-input placeholder="手机号/用户名" prefix-icon="el-icon-user" v-model="ruleForm.username" type="text" class="login-form-input"> </el-input>
            </el-form-item>
            <el-form-item prop="password">
              <el-input placeholder="密码" prefix-icon="el-icon-lock" v-model="ruleForm.password" show-password type="password" class="login-form-input"> </el-input>
            </el-form-item>
            <el-button type="primary" class="login-form-button" @click="submitForm('ruleForm')"> 登录 </el-button>
          </el-form>
          <template v-if="isWechatBtn">
            <el-divider></el-divider>
            <div class="login-other">
              <div>
                <a @click="registerForm">注册用户</a>
              </div>
            </div>
          </template>
        </div>
      </div>

      <div v-else class="login-right">
          <!-- 注册 -->
          <div class="login-form">
            <div class="login-form-title">用户注册</div>
            <el-form ref="form" :model="form" :rules="validatorRules" label-width="80px">
              <el-form-item label="区域" prop="facultyId">
                <el-select v-model="form.facultyId" @change="handleSchoolList" placeholder="请选择学校区域">
                  <el-option v-for="item in qyList" :key="item.id" :label="item.name" :value="item.id">
                    {{item.name}}
                  </el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="学校" prop="className">
                <el-select v-model="form.className" placeholder="请选择学校">
                  <el-option v-for="item in schoolList" :key="item.id" :value="item.name" :label="item.name"></el-option>
                </el-select>
              </el-form-item>
              <el-form-item label="身份" prop="code">
                <el-radio-group v-model="form.code" >
                  <el-radio label="1">学校负责人</el-radio>
                  <el-radio label="2">教师</el-radio>
                  <el-radio label="3">学生</el-radio>
                </el-radio-group>
              </el-form-item>

              <el-form-item label="姓名" prop="realname">
                <el-input v-model="form.realname" placeholder="请输入真实姓名"></el-input>
              </el-form-item>
              <el-row :gutter="10">
                <el-col :span="16">
                  <el-form-item label="手机号" prop="phone">
                    <el-input v-model="form.phone" placeholder="11 位手机号">
                    </el-input>
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <span style="font-size: 12px;line-height: 38px; color: #909399">手机号即为登录账号</span>
                </el-col>
              </el-row>

              <el-row :gutter="16">
                <el-col :span="16">
                  <el-form-item label="验证码" prop="captcha">
                    <el-input
                        v-model="form.captcha"
                        type="text"
                        placeholder="验证码"
                        suffix-icon="el-icon-tickets"
                    />
                  </el-form-item>
                </el-col>
                <el-col :span="8">
                  <el-button
                      ref="captcha"
                      class="getCaptcha"
                      :disabled="state.smsSendBtn"
                      @click.stop.prevent="getCaptcha"
                      v-text="!state.smsSendBtn && '获取验证码'||(state.time+' s')"></el-button>
                </el-col>
              </el-row>


              <el-form-item prop="password1" label="密码">
                <el-input
                    v-model="form.password1"
                    type="password"
                    autocomplete="false"
                    placeholder="至少8位密码，区分大小写">
                </el-input>
              </el-form-item>

              <el-form-item prop="password2" label="确认密码">
                <el-input v-model="form.password2" type="password" autocomplete="false" placeholder="确认密码"></el-input>
              </el-form-item>

              <el-form-item style="margin-top: -15px">
                <span style="color: #303133;font-size: 12px">注：<span style="color: red">*</span> 选项为必填项</span>
              </el-form-item>

              <el-form-item>
                <el-button type="primary" @click.stop.prevent="handleSubmit">注册</el-button>
                <el-button @click="loginForm" type="text" style="margin-left: 50px;">使用已有账号登录</el-button>
              </el-form-item>
            </el-form>
          </div>
      </div>
    </div>
    <!-- 底部 -->
    <div class="base-footer">
      <div class="copyright">
        <span><img src="@/assets/img/base/footer_addr.png" alt="" />地址：太原市小店区龙城大街102号盛锦国际B座6层</span>
        <span> 电话：0351-5615309</span>
      </div>
      <div class="copy">
        <span style="margin-right: 60px">版权所有：山西华兴科软有限公司 晋ICP备05002232号-4</span>
        <span>建议使用360浏览器极速模式,分辨率1600*900以上访问本网站</span>
      </div>
    </div>
  </div>
</template>

<script>
import { getSchoolList, login, wechatLogin,getSchoolId } from '@/api/login';
export default {
  data() {
    return {
      title: '职业教育数字化转型问卷调研系统',
      form: {},
      ruleForm: {
        schoolId: '' ,
        username: '',
        password: '',
      },
      options: [], //学校下拉框
      value: '',//学校转换名称
      rules: {
        username: [{ required: true, message: '请输入用户名', trigger: 'blur' }],
        password: [{ required: true, message: '请输入密码', trigger: 'blur' }],
      },
      validatorRules: {
        facultyId: [{ required: true, message: '请选择区域'}],
        className: [{ required: true, message: '请选择学校'}],
        code: [{ required: true, message: '请选择用户身份'}],
        realname: [{ required: true, message: '请输入用户姓名'}],
        password1: [
          { required: true, message: '请输入密码'},
          { validator: this.handlePasswordLevel }
        ],
        password2: [
          { required: true, message: '请确认密码' },
          { validator: this.handlePasswordCheck }
        ],
        phone: [
          { required: true, message: '请输入手机号' },
          { validator: this.handlePhoneCheck }
        ],
        captcha: [
          { required: true, message: '请输入验证码' },
          { validator: this.handleCaptchaCheck }
        ]
      },
      disabled:false,
      msg: '',
      text: '向右滑',
      num: 0,//验证次数+
      imgs: [require('@/assets/img/slide/1.jpg'), require('@/assets/img/slide/2.jpg')],//验证图片
      code: '',
      unionid: '',
      isWechatBtn:true,
      isLogin:true,
      isSlideCheck:false,

      qyList: [],
      schoolList: [],
      state: {
        time: 60,
        smsSendBtn: false,
        passwordLevel: 0,
        passwordLevelChecked: false,
        percent: 10,
        progressColor: '#FF0000'
      },
    };
  },
  created() {
    // this.checkBindWechat();
    // this.fetchData();
    this.handleQyList()
  },
  methods: {

    handlePasswordLevel(rule, value, callback) {
      let reg = /^(?=.*[a-zA-Z])(?=.*\d)(?=.*[~!@#$%^&*()_+`\-={}:";'<>?,./]).{8,}$/;
      if (!reg.test(value)) {
        callback(new Error('密码由9位数字、大小写字母和特殊符号组成!'))
      }
    },
    handlePasswordCheck(rule, value, callback) {
      let password = this.form['password1']
      //console.log('value', value)
      if (value === undefined) {
        callback(new Error('请输入密码'))
      }
      if (value && password && value.trim() !== password.trim()) {
        callback(new Error('两次密码不一致'))
      }
      callback()
    },
    handleCaptchaCheck(rule, value, callback){
      if(!value){
        callback(new Error("请输入验证码"))
      }else{
        callback();
      }
    },
    handlePhoneCheck(rule, value, callback) {
      var reg=/^1[3456789]\d{9}$/
      if(!reg.test(value)){
        callback(new Error("请输入正确手机号"))
      }else{
        var params = {
          phone: value,
        };
        // checkOnlyUser(params).then((res) => {
        //   if (res.success) {
        //     callback()
        //   } else {
        //     callback("手机号已存在!")
        //   }
        // })
      }
    },


    // 获取学校列表
    fetchData() {
      getSchoolList().then(res => {
        if (res.success) {
          const arr = res.result.map(item => {
            return {
              value: item.value,
              label: item.text,
            };
          });
          this.options = arr;
          if(localStorage.getItem('ss-schoolId')){
            this.ruleForm.schoolId = localStorage.getItem('ss-schoolId')
          }else{
            this.ruleForm.schoolId = this.options[0].value
          }
        }
      });
    },
    // checkBindWechat(){
    //   let mycode = this.$route.query.code;
    //   if (mycode) {
    //     var params = {
    //       code: mycode,
    //     };
    //     wechatLogin(params).then(res => {
    //       if (res.success) {
    //         this.unionid = res.result.unionid;
    //         // 已经绑定过
    //         if (res.result.status) {
    //           this.$message({
    //             message: res.message,
    //             type: 'success',
    //           });
    //           localStorage.setItem('ss-token', res.result.token);
    //           localStorage.setItem('ss-schoolId', res.result.userInfo.schoolId);
    //           localStorage.setItem('ss-userInfo', JSON.stringify(res.result.userInfo));
    //           localStorage.setItem('ss-className', res.result.userInfo.className);
    //           this.$router.push('/');
    //         } else {
    //           this.$confirm('确认绑定账号登录吗, 是否继续?', '提示', {
    //             confirmButtonText: '确定',
    //             cancelButtonText: '取消',
    //             type: 'success',
    //           }).then(() => {
    //             this.isWechatBtn = false;
    //             this.submitForm("wechatLogin");
    //           }).catch(()=>{
    //             this.$router.push(this.$route.path)
    //           });
    //         }
    //       } else if (res.success) {
    //         this.$message({
    //           message: res.message,
    //           type: 'warning',
    //         });
    //       }
    //     });
    //   }
    // },
    getSchoolId(code){
      getSchoolId(code).then(res=>{
        if(res.success){
          this.ruleForm.schoolId = res.result.id
          this.disabled = true;
        }
      })
    },
    // 账号密码登录
    submitForm(type) {
      if(type){
        this.ruleForm['thirdId'] = this.unionid
      }
      this.$refs.ruleForm.validate(valid => {
        if (valid) {
          // this.$store.dispatch('Login', this.ruleForm).then(response=>{
          //     console.log(response)
          //   })
          // 验证没超过3次
          // if (this.num <= 3) {
            this.ruleForm.schoolId = '1702577269460021249'
            login(this.ruleForm).then(response => {
              if (response.success) {
                localStorage.setItem('ss-schoolId', response.result.userInfo.schoolId);
                localStorage.setItem('ss-className', response.result.userInfo.className);
                localStorage.setItem('ss-userInfo', JSON.stringify(response.result.userInfo));
                localStorage.setItem('ss-token', response.result.token);
                // localStorage.setItem("ss-schoolName", res.result.userInfo.schoolName);
                this.$router.push('/myQuestion');
              } else {
                this.$message({
                  message: response.message,
                  type: 'warning',
                });
                this.num++;
              }
            });
          // } else {
          //   this.isSlideCheck = true;
          // }
        } else {
          return false;
        }
      });
    },
    onSuccess() {
      this.msg = '';
      this.isSlideCheck = false;
      this.$message.success('校验成功');
      this.$nextTick(() => {
        this.num = 1;
        this.submitForm();
      });
    },
    onFail() {
      this.$message.warning('校验失败');
      this.msg = '';
    },
    onRefresh() {
      this.msg = '';
    },



    getCaptcha(e) {
      e.preventDefault()
      let that = this
      let flag = true
      this.$refs.form.validateField('phone', (err) => {
            flag = false
          }
      );
      if (flag) {
        this.state.smsSendBtn = true;
        let interval = window.setInterval(() => {
          if (that.state.time-- <= 0) {
            that.state.time = 60;
            that.state.smsSendBtn = false;
            window.clearInterval(interval);
          }
        }, 1000);
        const hide = that.$message.info('验证码发送中..', 3);
        const params = {
          phone: this.form.phone,
          smsmode: "1"
        };
        that.$post("/school/sms", params).then((res) => {
          if (!res.success) {
            this.registerFailed(res.message);
            setTimeout(hide, 0);
          }
          setTimeout(hide, 500);
        }).catch(err => {
          setTimeout(hide, 1);
          clearInterval(interval);
          that.state.time = 60;
          that.state.smsSendBtn = false;
          this.requestFailed(err);
        });
      }
    },

    // 区域列表
    handleQyList(){
      this.$get("/filter/szhzx/qy").then((res) => {
        if (!res.success) {
          this.registerFailed(res.message)
        } else {
          this.qyList = res.result
        }
      })
    },

    // 学校列表
    handleSchoolList(){
      delete this.form.className
      this.$get("/filter/szhzx/schoolByQy",{facultyId:this.form.facultyId}).then((res) => {
        if (!res.success) {
          this.registerFailed(res.message)
        } else {
          this.schoolList = res.result
        }
      })
    },
    registerFailed(message) {
      this.$notify.error({
        title: "注册失败",
        message: message,
        duration: 2,
      });
    },

    handleSubmit() {
      let success = true
      this.$refs.form.validate((valid) => {
        success = valid
      })
      this.form.password = this.form.password1
      if (success) {
        this.$post("/school/register", this.form).then((res) => {
          if (!res.success) {
            this.registerFailed(res.message)
          } else {
            this.$notify.success({
              title: "注册成功",
              message: '用户注册成功',
              duration: 1000,
            });
            setTimeout(()=>{
              this.loginForm()
            },1000)
          }
        })
      }
    },

    registerForm(){
      this.$refs.ruleForm.clearValidate()
      this.isLogin = false
    },
    loginForm(){
      this.$refs.form.clearValidate()
      this.isLogin = true
    }
  }
};
</script>

<style scoped lang="scss">
/* 水平垂直居中 */
.a-auto {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
/* flex布局 */
.a-flex {
  display: flex;
}
.a-flex-jc-cen {
  justify-content: center;
}
.a-flex-jc-btw {
  justify-content: space-between;
}
.a-flex-jc-ard {
  justify-content: space-around;
}
.a-flex-ai-cen {
  align-items: center;
}
.back-btn{
  position: absolute;
  top: 10px;
  left: 10px;
  text-align: left;
}
#weixin {
  display: flex;
  justify-content: center;
  color: #3f84ee;
  cursor: pointer;
}
.login-bg {
  position: absolute;
  width: 100%;
  height: 100%;
  background: url('../../assets/img/login/login_bg.png') no-repeat;
  background-size: 100% 100%;
  overflow: hidden;
}
.login-auto {
  width: 1200px;
  height: 522px;
  margin: 0 auto;
  display: flex;
}
.login-left {
  height: 100%;
  flex: 1;
  display: flex;
  flex-direction: column;
  .login-title{
    font-size: 40px;
    color: #fff;
  }
  .login-xbg{
    flex: 1;
    background: url(~@/assets/img/login/photo_01.png) no-repeat left center;
    background-size: contain;
  }
}
.login-right {
  width: 480px;
  background: #fff;
  border-radius: 4px;
  box-shadow: 0px 0px 4px #fff;
  padding: 30px 30px 20px;
  box-sizing: border-box;
  position: relative;
  .login-form-title {
    text-align: center;
    font-size: 30px;
    color: #0072ff;
    font-weight: bold;
    letter-spacing: 2px;
  }
  form {
    margin: 26px 0 20px;
  }
  /deep/.schoolColor input{
    color: rgba(0, 0, 0, 0.65)
  }

  .login-form-input,
  .login-form-button{
    width: 100%;
  }
  .register-button{
    position: absolute;
    right:0;
  }
  .login-other {
    margin: 0 auto;
    text-align: center;
    p {
      line-height: 28px;
      font-size: 15px;
      text-align: center;
      user-select: none;
      color: #ccc;
    }
    img {
      display: block;
      margin: 10px auto;
      width: 30px;
      cursor: pointer;
    }
    a{
      line-height: 28px;
      font-size: 15px;
      color: #3f84ee;
      cursor: pointer;
    }
  }
}
.base-footer {
  position: absolute;
  bottom: 0;
  width: 100%;
  height: 66px;
  background-color: rgba(51, 177, 244, 0.6);
  color: #ffffff;
  line-height: 24px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  .copyright {
    display: flex;
    align-items: center;
    box-sizing: border-box;
    span {
      display: flex;
      align-items: center;
      img {
        display: inline-block;
        width: 16px;
        margin-right: 14px;
      }
      &:first-child {
        margin-right: 44px;
      }
    }
  }
}


.getCaptcha {
  display: block;
  width: 100%;
  height: 40px;
}
.form-width{
  width: 400px;
}
</style>
